import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Form,
  Button,
  Alert,
  InputGroup,
  Spinner,
  CardGroup,
  Card,
  Modal,
  Col
} from "react-bootstrap";
import { getKeywords, searchArtworks, tagImage, doUploadImg, isUserAdmin } from "../../api";
import { generateSelectOptions } from "../../utils";
import LocalizedStrings from 'react-localization';
import localizedStrings from '../../strings';
import moment from "moment";
import Select from 'react-select';
import CardsView from "../CardsView";
import TableView from "../TableView";
import FileUpload from "../fileUpload";

const minRange = moment({ year: 1897, month: 0 });
const maxRange = moment({ year: 2025, month: 11 });

const views = Object.freeze({ "TableView": 1, "CardsView": 2 })


const months = generateSelectOptions(1, 12);
const years = generateSelectOptions(minRange.year(), maxRange.year());

let strings = new LocalizedStrings(localizedStrings);

function Homepage({ onLogout }) {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [noArtworksFound, setNoArtworksFound] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [next, setNext] = useState(0);
  const [dbsize, setDbsize] = useState(0);
  const [totalSearch, setTotalSearch] = useState(0);
  const [artworks, setArtworks] = useState([]);
  const [selImage, setSelImage] = useState(null);
  const [showEditKeywords, setShowEditKeywords] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [availKeywords, setAvailKeywords] = useState(null);
  const [showAvailKeywords, setShowAvailKeywords] = useState(false);

  const [language, setLanguage] = useState("");
  const [textDir, setTextDir] = useState("RTL");

  const [fromMonth, setFromMonth] = useState(minRange.month() + 1);
  const [toMonth, setToMonth] = useState(maxRange.month() + 1);

  const [fromYear, setFromYear] = useState(minRange.year());
  const [toYear, setToYear] = useState(maxRange.year());

  const [viewType, setViewType] = useState(views.CardsView);
  const [isRandom, setIsRandom] = useState(false);

  const [miniMode, setMiniMode] = useState(false);

  const toMonthRef = useRef(null);
  const toYearRef = useRef(null);
  const fromMonthRef = useRef(null);
  const fromYearRef = useRef(null);

  const scrollRef = useRef(null);
  // const toMonthEngRef = useRef(null);
  // const toYearEngRef = useRef(null);
  // const fromMonthEngRef = useRef(null);
  // const fromYearEngRef = useRef(null);

  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);

  console.log('width: ', windowWidth.current);
  console.log('height: ', windowHeight.current);

  useEffect(() => {
    onLanguageChanged("heb")
    checkIfAdmin()

    //strings.setLanguage(language);
    //onSearchArtworks();
    // getKeywords()
    //   .then(resp => {
    //     console.log(resp)
    //     let str = "";
    //     resp.forEach(keyword => str += (keyword + " "))
    //     setAvailKeywords(str)
    //   })
  }, [])

  //const handleCloseEditKeywords = () => { setSelImage(null); setTagKeywords(""); setShowEditKeywords(false); }

  const handleCloseUpload = () => { setShowUpload(false); }

  // const handleShowEditKeywords = (image) => { setSelImage(image); setTagKeywords(image.Keywords); setShowEditKeywords(true) }


  const ToMonth = (val) => { setIsRandom(false); setToMonth(val) }
  const ToYear = (val) => { setIsRandom(false); setToYear(val) }
  const FromMonth = (val) => { setIsRandom(false); setFromMonth(val) }
  const FromYear = (val) => { setIsRandom(false); setFromYear(val) }
  const onClearSearch = (event) => {
    toMonthRef.current.clearValue();
    toYearRef.current.clearValue();
    fromMonthRef.current.clearValue();
    fromYearRef.current.clearValue();

    setFromMonth(minRange.month() + 1);
    setToMonth(maxRange.month() + 1);

    setFromYear(minRange.year());
    setToYear(maxRange.year());
    setKeyword("");
    setIsRandom(false);

    setArtworks([]);
    setNext(0)
    setTotalSearch(0)
    setDbsize(0)
    setNoArtworksFound(false);
    //setTimeout(function(){ onSearchArtworks(); }, 500);
    setIsFirstLoad(true);
  }

  const checkIfAdmin = async () => {
    let admin = await isUserAdmin()
    setIsAdmin(admin)
  }

  const onChangeKeyword = (event) => {
    setKeyword(event.target.value);
    setIsRandom(false);
  };

  const handleCloseUploading = () => {
    setIsUploading(false)
  }

  const onUpload = async (fileToUpload, thumb, caption, notes, tagsArray, fileDate) => {
    try {
      setShowUpload(false)
      setIsUploading(true)

      console.log(`${fileToUpload.name}, ${fileDate}`);

      let ret = await doUploadImg(fileToUpload, thumb, caption, notes, tagsArray, fileDate);
      setIsUploading(false)
      console.log(ret)

    } catch (e) {
      setIsUploading(false)
      console.log(e)
      alert("Upload error: " + e);
    }
  };

  const onLanguageChanged = (lang) => {
    console.log("onLanguageChanged: " + lang);
    strings.setLanguage(lang);
    setTextDir(lang === "heb" ? "RTL" : "LTR")
    setLanguage(lang);
  };

  const onSearchArtworks = async () => {
    setIsFirstLoad(false);
    setIsLoading(true);
    setNext(0)
    let keys = isRandom ? "randomize" : (keyword && keyword.length ? keyword : "getall")

    let from = moment({ year: fromYear, month: fromMonth - 1 });
    let to = moment({ year: toYear, month: toMonth - 1 });

    console.log(from + " -- " + to);

    const searchRet = await searchArtworks({ keyword: keys, next: -1, from: from.valueOf(), to: to.valueOf() });
    setIsLoading(false);
    if (searchRet) {
      setArtworks(searchRet.data);
      setNext(searchRet.next)
      setTotalSearch(searchRet.totalSearch)
      setDbsize(searchRet.DBsize)
      let noArtwork = !searchRet || !searchRet.data || !searchRet.data.length;
      setNoArtworksFound(noArtwork);
      setMiniMode(!noArtwork)
      if (scrollRef.current) {
        scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  };

  const onNext = async (event) => {
    event.preventDefault();
    onNextInter();
  }
  const onNextInter = async (event) => {
    setIsLoading(true);
    let keys = isRandom ? "randomize" : (keyword && keyword.length ? keyword : "getall")

    let from = moment({ year: fromYear, month: fromMonth - 1 });
    let to = moment({ year: toYear, month: toMonth - 1 });

    console.log(from + " -- " + to);
    const searchRet = await searchArtworks({ keyword: keys, next: next, from: from.valueOf(), to: to.valueOf() });
    if (searchRet) {
      setArtworks(artworks.concat(searchRet.data));
      setNext(searchRet.next)
    }
    setIsLoading(false);
  };


  // const onEditKeywords = async (event) => {
  //   event.preventDefault();
  //   setShowEditKeywords(false)
  //   setIsLoading(true);

  //   console.log(selImage);

  //   let result = await tagImage(selImage, tagKeywords);

  //   if (result === "") {
  //     selImage.Keywords = tagKeywords
  //   }

  //   setIsLoading(false);
  //   setSelImage(null);
  //   setTagKeywords("");

  //   alert(result === "" ? "Changes Saved!" : result);
  // };

  return (
    <Container fluid>
      {/* <h1>{process.env.NODE_ENV}</h1>
      <h1>{process.env.REACT_APP_NOT_SECRET_CODE}</h1>
      <h1>{process.env.REACT_AWS_DEPLOYMENT ? process.env.REACT_AWS_DEPLOYMENT : "no process.env.REACT_AWS_DEPLOYMENT"}</h1> */}
      <Row className="mt-2 mb-2 justify-content-end" noGutters>
        {!miniMode &&
          <Button variant="outline-danger" onClick={onLogout} style={{ marginRight: "10px" }}>
            {strings.logOut}
          </Button>
        }
        {!miniMode && isAdmin &&
          <Button variant="outline-primary" onClick={() => setShowUpload(true)} style={{ marginRight: "10px" }}>
            {strings.upload}
          </Button>
        }
        {!miniMode &&
          <Button variant="outline-secondary" onClick={() => onLanguageChanged("en")}>
            <img src="USA-Flag-icon.png" alt="English" />
          </Button>
        }
        {!miniMode &&
          <Button variant="outline-secondary" onClick={() => onLanguageChanged("heb")}>
            <img src="Israel-Flag-icon.png" alt="עברית" />
          </Button>
        }
      </Row>
      <Row noGutters>
        <h1 style={{ margin: "auto" }}>{strings.Welcome}</h1>
      </Row>
      <Row noGutters>
        <Form className="mb-2" style={{ maxWidth: "500px", margin: "auto" }} onSubmit={(event) => { event.preventDefault(); onSearchArtworks() }}>
          <InputGroup>
            <Button
              variant="outline-primary"
              disabled={false}
              onClick={() => setMiniMode(!miniMode)}
            >
              <i class="fa fa-bars"></i>
            </Button>

            {!isFirstLoad && <Button
              variant="outline-primary"
              disabled={false}
              onClick={onClearSearch}

            >
              <i class="fa fa-remove"></i>
            </Button>
            }
            <Form.Control
              type="text"
              placeholder={strings.multiple_keywords}
              onChange={onChangeKeyword}
              value={keyword}
              dir={textDir}

            />
            <Button
              variant="outline-primary"
              disabled={false}
              type="submit"
            >
              <i class="fa fa-search"></i>
            </Button>
            {(strings.getLanguage() === "heb") &&
              <InputGroup style={{ display: miniMode ? 'none' : 'flex' }}>

                <Select components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  defaultValue={toMonth}
                  onChange={(e) => ToMonth(e ? e.value : maxRange.month() + 1)}
                  options={months}
                  placeholder={strings.month}
                  className="select"
                  dir={textDir}
                  ref={toMonthRef}
                />


                <Select components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  defaultValue={toYear}
                  onChange={(e) => ToYear(e ? e.value : maxRange.year())}
                  options={years}
                  placeholder={strings.to}
                  className="select"
                  dir={textDir}
                  ref={toYearRef}
                />


                <Select components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  defaultValue={fromMonth}
                  onChange={(e) => FromMonth(e ? e.value : minRange.month() + 1)}
                  options={months}
                  placeholder={strings.month}
                  className="select"
                  dir={textDir}
                  ref={fromMonthRef}
                />


                <Select components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  defaultValue={fromYear}
                  onChange={(e) => FromYear(e ? e.value : minRange.year())}
                  options={years}
                  placeholder={strings.from}
                  className="select"
                  dir={textDir}
                  ref={fromYearRef}
                />

              </InputGroup>
            }

            {(strings.getLanguage() !== "heb") &&
              <InputGroup style={{ display: miniMode ? 'none' : 'flex' }}>


                <Select components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  defaultValue={fromYear}
                  onChange={(e) => FromYear(e ? e.value : minRange.year())}
                  options={years}
                  placeholder={strings.from}
                  className="select"
                  dir={textDir}
                  ref={fromYearRef}
                />


                <Select components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  defaultValue={fromMonth}
                  onChange={(e) => FromMonth(e ? e.value : minRange.month() + 1)}
                  options={months}
                  placeholder={strings.month}
                  className="select"
                  dir={textDir}
                  ref={fromMonthRef}
                />

                <Select components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  defaultValue={toYear}
                  onChange={(e) => ToYear(e ? e.value : maxRange.year())}
                  options={years}
                  placeholder={strings.to}
                  className="select"
                  dir={textDir}
                  ref={toYearRef}
                />

                <Select components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  defaultValue={toMonth}
                  onChange={(e) => ToMonth(e ? e.value : maxRange.month() + 1)}
                  options={months}
                  placeholder={strings.month}
                  className="select"
                  dir={textDir}
                  ref={toMonthRef}
                />

              </InputGroup>
            }

            {/* <Button
              variant="outline-primary"
              disabled={false}
              type="submit"
              style={{ width: "100%" }}
            >
              {strings.SearchDB}
            </Button> */}
          </InputGroup>

          {!isFirstLoad && artworks.length > 0 && (
            <Row className="mt-2" noGutters>
              <h5 style={{ margin: "auto", fontSize: "small" }}>{strings.formatString(strings.searchStats, totalSearch, artworks.length)}</h5>
            </Row>
          )}
          {/* <Row noGutters>
            <small>
              <i style={{ margin: "10px" }} className="fa fa-arrow-right" aria-hidden="true"></i>
              Use '+' sign to search for all keywords
              {strings.multiple_keywords}
            </small>
          </Row> */}
          {/* {availKeywords && <Row noGutters>
            {!showAvailKeywords && <small>
              <i style={{ margin: "10px" }} className="fa fa-plus-square" aria-hidden="true" onClick={() => setShowAvailKeywords(!showAvailKeywords)}></i>
              Show keywords
            </small>
            }
            {showAvailKeywords && <small>
              <i style={{ margin: "10px" }} className="fa fa-minus-square" aria-hidden="true" onClick={() => setShowAvailKeywords(!showAvailKeywords)}></i>
              Hide keywords
            </small>
            }
          </Row>
          }
          {availKeywords && showAvailKeywords && <Row noGutters>
            <small>
              {availKeywords}
            </small>
          </Row>
          } */}
        </Form>
      </Row >
      {!isFirstLoad && isLoading && !noArtworksFound && (!artworks.length) &&
        <Row className="justify-content-center mb-5">
          <Spinner animation="border" variant="primary" />
        </Row>

      }
      {
        !isFirstLoad && noArtworksFound && !isLoading &&
        <Alert variant={"info"} style={{ textAlign: "center" }}>
          {strings.noResults}
        </Alert>
      }
      {!isFirstLoad && !noArtworksFound && !isLoading && !miniMode &&
        <Row className="w-100 mb-3 justify-content-center" noGutters>
          <Button variant="outline-secondary" onClick={() => setViewType(views.TableView)}>
            <img src="table.png" alt="table view" />

          </Button>
          <Button variant="outline-secondary" onClick={() => setViewType(views.CardsView)}>
            <img src="thumbs.png" alt="thumbs view" />
          </Button>

        </Row>
      }
      {!isFirstLoad && !noArtworksFound &&
        <Row className="w-100 resultsParent" id="scrollableDiv" noGutters
          ref={scrollRef}
          style={{ height: miniMode ? "calc(100vh - 140px)" : "calc(100vh - 260px)" }}>
          {(viewType == views.CardsView) ? <CardsView fetchMoreData={onNextInter} maxData={totalSearch} artworks={artworks} strings={strings} /> :
            <TableView fetchMoreData={onNextInter} maxData={totalSearch} artworks={artworks} strings={strings} />}
        </Row>
      }
      {/*
        !isFirstLoad && !isLoading && (
          <Row className="mt-2 mb-5" noGuttersnoGutters>
            <h5 style={{ margin: "auto" }}>{strings.formatString(strings.searchStats, totalSearch, artworks.length)}</h5>
          </Row>
        )
        */}
      {/*
        !isFirstLoad && !isLoading && !noArtworksFound && next > 0 &&
        <Row noGutters>
          <Form className="w-100 mb-5" onSubmit={onNext} style={{ margin: "auto" }}>
            <InputGroup>
              <InputGroup.Prepend style={{ margin: "auto" }}>
                <Button
                  variant="outline-primary"
                  type="submit"
                >
                  {strings.moreResults}
                </Button>
              </InputGroup.Prepend>
            </InputGroup>
          </Form>
        </Row>
      */}
      {/*
        selImage && showEditKeywords &&
        <Modal
          show={showEditKeywords}
          onHide={handleCloseEditKeywords}
        >
          <Modal.Header closeButton>
            <Modal.Title>{selImage.Caption}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <Row className="mt-2" noGutters>
                <h6>
                  {strings.tagPic}
                </h6>
              </Row>
              <Row noGutters>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="=> yos, ruty, mom..."
                    onChange={onChangeTagKeywords}
                    value={tagKeywords}
                  />
                </InputGroup>
              </Row>

            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={onEditKeywords}>{strings.save}</Button>
            <Button variant="secondary" onClick={handleCloseEditKeywords}>{strings.close}</Button>
          </Modal.Footer>
        </Modal>
    */}
      {showUpload && <FileUpload onHide={handleCloseUpload} onUpload={onUpload} language={language} textDir={textDir}> </FileUpload>}
      <Modal
        show={isUploading}
        backdrop="static"
        onHide={handleCloseUploading}
      >
        <Modal.Header>
          <Modal.Title>{strings.uploading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row className="justify-content-center mb-5">
              <Spinner animation="border" variant="primary" />
            </Row>
          </Container>
        </Modal.Body>
      </Modal>


    </Container >
  );
}

export default Homepage;
