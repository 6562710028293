// const Headers = () => {
//   return { "Content-Type": "application/json", "Authentication" : "Bearer " + localStorage.getItem("token") }
// }
import moment from "moment";
import jwtDecode from "jwt-decode";
import { API, Storage, Auth } from "aws-amplify";

const apiName = 'jmimgdbapi';
const path = '/pics';

let accessID = null;
let isAdmin = null;

const Headers = async () => {
  if (!accessID) {
    let session = await API.Auth.currentSession();
    accessID = session.getIdToken().getJwtToken();
    console.log("accessID: " + accessID);
  }

  return { "Authorization": accessID, "Content-Type": "application/json" }
}

const serverUrl = "https://wjwirhk265.execute-api.eu-central-1.amazonaws.com/staging";
//const serverUrl = "https://www.jmpics.net/staging";

//const serverUrl = "http://localhost:4000";

export async function isUserAdmin() {
  if (process.env.REACT_APP_LOCAL_SERVER != null) {
    return true
  }

  //if (isAdmin == null) {
    let ses = await API.Auth.currentSession();
    var sessionIdInfo = jwtDecode(ses.getIdToken().getJwtToken());
    console.log(sessionIdInfo);
    isAdmin = false;
    let groups = sessionIdInfo['cognito:groups'];
    console.log(groups)
    if (groups && groups.length > 0 && groups.includes("admins")) {
     isAdmin = true;
    }
    console.log(`us admin ${isAdmin}`)
  //}

  return isAdmin;
}

export async function login({ email, password }) {
  return {};
  return await fetch(serverUrl + "/api/auth/login", {
    method: "POST",
    body: JSON.stringify({ email, password }),
    headers: await Headers(),
  })
    .then((response) => {
      // If request is not successful, display error message
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }

      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
}

async function doLocalGet(searchTerm) {
  let serverUrl = `http://${process.env.REACT_APP_LOCAL_SERVER}/pics?`

  let entries = Object.entries(searchTerm);
  entries.map(([k, v]) => {
    serverUrl += `${k}=${v}&`;
  })
  console.log(serverUrl)

  try {
    const response = await fetch(serverUrl, {
      method: "GET",
      headers: await Headers(),
    })
    return { status: 200, data: await response.json() }
  } catch (err) {
    console.log(err);
    return { status: 500, message: err, data: null }
  };
}

export async function APIGet(searchTerm) {

  let useLocalServer = process.env.REACT_APP_LOCAL_SERVER != null;
  console.log(`useLocalServer: ${useLocalServer}`)
  let response = null;
  if (useLocalServer) {
    response = await doLocalGet(searchTerm)
  } else {
    const params = {
      headers: await Headers(),
      mode: 'cors',
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: searchTerm
    };

    response = await API.get(apiName, path, params);
  }
  if (!response.status == 200) {
    throw new Error("HTTP status " + response.status);
  }

  return response;
}

async function doLocalPost(body) {
  let serverUrl = `http://${process.env.REACT_APP_LOCAL_SERVER}`
  console.log(serverUrl + path)
  try {
    const response = await fetch(serverUrl + path, {
      method: "POST",
      body: JSON.stringify(body),
      headers: await Headers(),
    })
    return { status: 200, data: await response.json() }
  } catch (err) {
    console.log(err);
    return { status: 500, message: err, data: null }
  };
}

export async function APIPost(body) {
  const params = {
    body: body, // replace this with attributes you need
    headers: await Headers(),
    mode: 'cors',
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let useLocalServer = process.env.REACT_APP_LOCAL_SERVER != null;
  console.log(`useLocalServer: ${useLocalServer}`)

  let response = !useLocalServer ? (await API.post(apiName, path, params)) : (await doLocalPost(body));
  console.log(response)
  if (!response || response.status != 200) {
    throw new Error("HTTP status " + (response ? response.status : "Is NULL"));
  }
  console.log(`APIPost: ${response}`)
  return response;
}

export async function doUpdateImgData(fullPath, fileName, caption, notes, keywords, fileDate) {
  let body = { fullPath, fileName, caption, notes, keywords, fileDate: fileDate.valueOf() }
  try {
    console.log(body);
    let response = await APIPost(body)
    console.log(`doUpdateImgData: ${response}`)
    return response.status === 200;
    //alert("metadate Uploaded")
  } catch (e) {
    console.log(`doUpdateImgData catch: ${e}`)
    alert(`Error when updating metadate: ${e}`)
    return false;
  }
}

export async function doUploadImg(image, thumb, caption, notes, keywords, fileDate) {
  try {

    let today = moment();
    let datepre = moment(fileDate)
    let pre = datepre.year() + "-" + datepre.month() + "-";
    let filename = pre + image.name.replaceAll(" ", "_")
    let fullPath = `uploads/${today.year()}_${today.month() + 1}/${filename}`
    console.log(fullPath);

    var options = {
      ACL: 'public-read',
      level: 'public',
      contentType: "image/png"
    }

    await Storage.put("pics/"+fullPath, image, options, {
      //contentType: "image/png", // contentType is optional
      progressCallback(progress) {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      },
      errorCallback(err){
        throw new Error(err);
      }
    });

    await Storage.put("thumbs/"+fullPath, thumb, options, {
      //contentType: "image/png", // contentType is optional
      progressCallback(progress) {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      },
      errorCallback(err){
        throw new Error(err);
      }
    });

    console.log("File Uploaded, trying to update metadata");

    let ret = await doUpdateImgData(fullPath, filename, caption, notes, keywords, fileDate)
    if (!ret) {
      alert("Upload error")
      return false;
    }

    //alert("File Uploaded")
    return true;
  }
  catch (err) {
    console.log(err);
    alert(`Upload error: ${err}`)
    return false;
  };
}


export async function searchArtworks(searchTerm) {
  let { keyword, next, asc, from, to } = searchTerm;
  let isAsc = asc != "false";
  console.log(from)
  let aFrom = from ? from : Number.MIN_VALUE;
  let aTo = to ? to : Number.MAX_VALUE;
  try {
    let response = await APIGet({ keyword, next, "asc": isAsc, "range": aFrom + "_" + aTo });

    await Promise.all(
      response.data.data.map(async (pic) => {
        const image_url = await Storage.get(pic.image_url, { level: "public" });
        pic.image_url = image_url
        const thumbnail = await Storage.get(pic.thumbnail, { level: "public" });
        pic.thumbnail = thumbnail
        return pic;
      })
    );

    console.log(response.data)

    return response.data;
  }
  catch (err) {
    console.log(err);
  };
}

export async function tagImage(image, keywords) {
  return await fetch(serverUrl + `/api/homepage/tagImage`, {
    method: "POST",
    headers: await Headers(),
    body: JSON.stringify({ image: image.SourceFile, keywords: keywords })
  })
    .then((response) => {
      // If request is not successful, display error message
      if (!response.ok) {
        return "HTTP status " + response.status;
      }

      return ""
    })
    .catch((err) => {
      console.log(err);
      return "Error: " + err.toString();
    });
}

export async function getKeywords() {
  return await fetch(serverUrl + `/api/homepage/getKeywords`, {
    method: "GET",
    headers: await Headers(),
  })
    .then((response) => {
      // If request is not successful, display error message
      if (!response.ok) {
        console.log("HTTP status " + response.status)
        return [];
      }

      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return "Error: " + err.toString();
    });
}