import React, { useEffect, useState } from "react";
import Homepage from "./components/homepage";
import "@aws-amplify/ui-react/styles.css";
import {
  withAuthenticator,
} from "@aws-amplify/ui-react";


function App({ signOut }) {
  return (
    <Homepage onLogout={signOut} />
  )

}

export default withAuthenticator(App);


