import React from "react";
import {
  Row,
  Col,
} from "react-bootstrap";
import moment from "moment";

function PicRow(props) {
  let pic = props.pic ? props.pic : {}
  let date = pic.DateTime ? moment(pic.DateTime) : null;
  console.log(props)
  let eng = props && props.strings && (props.strings.getLanguage() !== "heb");
  let picClassName = "col-4 col-sm-2 col-lg-1 picRowItem " +  (eng ? "order-first" : "order-last")
  if ( eng ) {
    return (
      <Row noGutters className="w-100" style={{ background: props.background, fontWeight: props.header ? 700 : "normal" }}>
        <Col className={picClassName}>
          {!props.header &&
            <a
              href={pic.image_url ? pic.image_url : ""}
              target="_blank"
              rel="noreferrer"
              aria-current="true"
            >
              <img variant="top" src={pic.thumbnail ? pic.thumbnail : "no_preview.png"} style={{ width: "110px", height: "110px" }} />
            </a>
          }
        </Col>

        <Col className="col-8 col-sm-10 col-lg-11 picRowItem" >
          <Row noGutters className="w-100 h-100" >
            <Col className="col-12 col-sm-4 col-lg-4 picRowItem" >{props.header ? props.strings.table_header_file : pic.FileName}</Col>
            <Col className="col-sm-2 col-lg-2 picRowItem d-none d-sm-block" >{props.header ? props.strings.table_header_date : date ? date.month() + 1 + "/" + date.year() : ""}</Col>
            <Col className="col-12 col-sm-2 col-lg-2 picRowItem" >{props.header ? props.strings.table_header_caption : pic.Caption}</Col>
            <Col className="col-12 col-sm-2 col-lg-2 picRowItem" >{props.header ? props.strings.table_header_notes : pic.Notes}</Col>
            <Col className="col-12 col-sm-2 col-lg-2 picRowItem" >{props.header ? props.strings.table_header_Keywords : pic.Keywords}</Col>
          </Row>
        </Col>

      </Row>
    )
    }

    return (
      <Row noGutters className="w-100" style={{ background: props.background, fontWeight: props.header ? 700 : "normal" }}>
        <Col className={picClassName}>
          {!props.header &&
            <a
              href={pic.image_url ? pic.image_url : ""}
              target="_blank"
              rel="noreferrer"
              aria-current="true"
            >
              <img variant="top" src={pic.thumbnail ? pic.thumbnail : "no_preview.png"} style={{ width: "110px", height: "110px" }} />
            </a>
          }
        </Col>

        <Col className="col-8 col-sm-10 col-lg-11 picRowItem" >
          <Row noGutters className="w-100 h-100" >
            <Col className="col-12 col-sm-2 col-lg-2 picRowItem" >{props.header ? props.strings.table_header_Keywords : pic.Keywords}</Col>
            <Col className="col-12 col-sm-2 col-lg-2 picRowItem" >{props.header ? props.strings.table_header_notes : pic.Notes}</Col>
            <Col className="col-12 col-sm-2 col-lg-2 picRowItem" >{props.header ? props.strings.table_header_caption : pic.Caption}</Col>
            <Col className="col-sm-2 col-lg-2 picRowItem d-none d-sm-block" >{props.header ? props.strings.table_header_date : date ? date.month() + 1 + "/" + date.year() : ""}</Col>
            <Col className="col-12 col-sm-4 col-lg-4 picRowItem" >{props.header ? props.strings.table_header_file : pic.FileName}</Col>
          </Row>
        </Col>

      </Row>
    )
}

export default PicRow;
