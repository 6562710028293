const localizedStrings = {
    heb: {
        Login: "התחבר",
        EnterEmail: "אימייל",
        EnterPassword: "סיסמה",
        LoginError: "אימייל לא קיים או לתואם לסיסמה. נסה שנית",
        Submit: "שלח",
        logOut: "התנתק",
        upload: "העלה",
        uploading: "מעלה...",
        uploadTitle: "בחר קובץ להעלות",
        tags: "תגיות",
        Welcome: "התמונות של יוסי",
        multiple_keywords: "הכנס מילות חיפוש באנגלית",
        SearchDB: "חפש",
        Clear: "נקה",
        noResults: "לא נמצאו תמונות המתאימות לחיפוש",
        moreResults: "...עוד",
        tagPic: "הכנס מילות חיפוש באנגלית לסמן את התמונה",
        save: "שמור",
        close: "סגור",
        searchStats: "נמצאו {0} תמונות, מראה {1}",
        from: "....מ",
        to: "...עד",
        month: "...חודש",
        year: "... שנה",
        toYear: "... עד שנה",
        fromYear: "... משנה",
        justYear: "שנה",
        table_header_file: "קובץ",
        table_header_date: "תאריך",
        table_header_caption: "שם",
        table_header_notes: "הערות",
        table_header_Keywords: "מילות מפתח",
        caption: "כותרת",
        notes: "הערות"
        },
    en: {
        Login: "Login",
        EnterEmail: "Enter email",
        EnterPassword: "Password",
        LoginError: "The email address and password you entered don't match any account. Please try again.",
        Submit: "Submit",
        logOut: "Log out",
        upload: "Upload",
        uploading: "Uploading...",
        uploadTitle:"Select File To Upload",
        tags: "keywords",
        Welcome: "Yossi's Pics",
        multiple_keywords: "Enter keywords to search",
        SearchDB: "Search DB",
        Clear: "Clear",
        noResults: "No results were found for the entered keyword/s.",
        moreResults: "More...",
        tagPic: "Enter one or multiple keywords to tag the image",
        save: "Save",
        close: "Close",
        searchStats: "Found {0} pics, showing {1}",
        month: "Month...",
        year: "Year...",
        from: "From...",
        toYear: "To Year",
        fromYear: "From Year",
        justYear: "Year",
        to: "To...",
        table_header_file: "File",
        table_header_date: "Date",
        table_header_caption: "Caption",
        table_header_notes: "Notes",
        table_header_Keywords: "Keywords",
        caption: "Caption",
        notes: "Notes"
    }
}

export default localizedStrings;

