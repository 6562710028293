import React from "react";
import InfiniteScroll from 'react-infinite-scroll-component';

import {

  CardGroup,
  Card,
  Row,
  Spinner
} from "react-bootstrap";

function CardsView(props) {

  return (
        <InfiniteScroll
          scrollableTarget="scrollableDiv"
          dataLength={props.artworks ? props.artworks.length : 0}
          next={props.fetchMoreData}
          hasMore={(props.artworks ? props.artworks.length : 0) < props.maxData}
          loader={<Row className="justify-content-center mb-5">
          <Spinner animation="border" variant="primary" />
        </Row>}
        >
          <Row xs={1} sm={2} md={3} lg={6} xl={8} className="w-100" noGutters>
        {props.artworks && props.artworks.map((artwork, idx) => {
          const {
            SourceFile,
            FileName,
            Caption,
            image_url,
            Notes,
            Keywords,
            thumbnail
          } = artwork;
          return (
            <Card key={`artwork-${SourceFile}`} style={{ minWidth: "240px"}}>
              <a
                href={image_url}
                target="_blank"
                rel="noreferrer"
                aria-current="true"
                style={{ height: "70%"}}
              >
                <Card.Img variant="top" src={thumbnail ? thumbnail : "no_preview.png"} style={{ height: "100%"}}/>
              </a>
              <Card.Body >
                {/* <Card.Title>{FileName}</Card.Title> */}
                <Card.Text style={{ color: "black" }}
                  className="font-bold"

                  // style={{ whiteSpace: "pre-line" }}
                >
                  <a className="text-muted" style={{fontSize: "12px"}}>{FileName}</a>

                  <br />
                  <a  style={{fontSize: "18px", fontWeight: 700}}>{Caption}</a>


                  <br />
                  <a  style={{fontSize: "18px"}}>{Notes}</a>

                  <br />
                  <a  style={{fontSize: "14px"}}>{Keywords}</a>

                  {/* <i style={{ margin: "10px" }} class="fa fa-pencil" aria-hidden="true" onClick={() => handleShowEditKeywords(artwork)}></i> */}
                </Card.Text>
                {/* <Card.Text>
                    <small className="text-muted">{medium_display}</small>
                  </Card.Text> */}
              </Card.Body>
            </Card>
          );
        })}
         </Row>
        </InfiniteScroll>
       );
}

export default CardsView;
