import React from "react";
import {
  Row,
  Col,
  Spinner
} from "react-bootstrap";
import PicRow from "../PicRow";
import InfiniteScroll from 'react-infinite-scroll-component';

function TableView(props) {
  let bkCol = false;
  let eng = props && props.strings && (props.strings.getLanguage() !== "heb");

  return (
    <InfiniteScroll
      scrollableTarget="scrollableDiv"
      dataLength={props.artworks ? props.artworks.length : 0}
      next={props.fetchMoreData}
      hasMore={(props.artworks ? props.artworks.length : 0) < props.maxData}
      loader={<Row className="justify-content-center mb-5">
        <Spinner animation="border" variant="primary" />
      </Row>}
    >
      <Row style={{ textAlign: (eng ? "left" : "right") }} noGutters>
        <Col className="d-none d-sm-block">
          <PicRow header background="lightGrey" strings={props.strings} xsHidden />
        </Col>
        {props && props.artworks && props.artworks.map((pic, idx) => {
          bkCol = !bkCol;
          return (<PicRow pic={pic} background={bkCol ? "WhiteSmoke" : "Cornsilk"} strings={props.strings} />)
        })}
      </Row>
    </InfiniteScroll>
  )
}
export default TableView;
