import React, { useState, useEffect, useRef } from "react";

import {
  Container,
  Row,
  Modal,
  Form,
  Button,
  InputGroup,
} from "react-bootstrap";
import LocalizedStrings from 'react-localization';
import localizedStrings from '../../strings';
import moment from "moment";
import Select from 'react-select';
import { generateSelectOptions } from "../../utils";
import Resizer from "react-image-file-resizer";

let strings = new LocalizedStrings(localizedStrings);
const minRange = moment({ year: 1800, month: 0 });
const maxRange = moment();
const months = generateSelectOptions(1, 12);

function FileUpload(props) {
  const [tagKeywords, setTagKeywords] = useState("");
  const [caption, setCaption] = useState("");
  const [notes, setNotes] = useState("");
  const [fileToUpload, setFileToUpload] = useState(null);
  const [thumbUri, setThumbUri] = useState(null);
  const [thumbFile, setThumbFile] = useState(null);
  const [month, setMonth] = useState(-1);
  const [year, setYear] = useState("");

  useEffect(() => {
    strings.setLanguage(props.language);
  }, [props])

  const handleFileChange = (e) => {
    //console.log(e.target.files)
    if (e.target.files) {
      let newFile = e.target.files[0]
      setFileToUpload(newFile);
      createThumb(newFile);
    }
  };

  // const onChangeCaption = (event) => {
  //   setTagKeywords(event.target.value);
  // };
  // const onChangeTagKeywords = (event) => {
  //   setTagKeywords(event.target.value);
  // };
  // const onChangeTagKeywords = (event) => {
  //   setTagKeywords(event.target.value);
  // };
  const createThumb = async (newFile) => {
    const resizeFile = (file) =>
      new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          400,
          400,
          "JPEG",
          100,
          0,
          (uri) => {
            resolve(uri);
          },
          "file"
        );
      });

    let aThumbFile = await resizeFile(newFile);
    let aThumbUri = URL.createObjectURL(aThumbFile);
    console.log(aThumbUri)
    setThumbFile(aThumbFile)
    setThumbUri(aThumbUri)
  }

  const onUpload = () => {
    try {
      if (!fileToUpload) {
        alert("file is empty");
        return;
      }
      console.log(fileToUpload);

      let tags = tagKeywords.replaceAll(" ", ",").split(',');
      tags = tags.filter((v) => v !== "");
      if ((!tags || tags.length <= 0) && !caption && !notes) {
        alert("Must have caption, notes or keywords");
        return;
      }

      console.log(`${caption}, ${notes}, ${JSON.stringify(tags)}`);

      let newYear = Number(year);
      console.log(minRange.year());

      if (!year || newYear === NaN) {
        alert("Year is not valid");
        return;
      }

      if (month < 0 || month > 12) {
        alert("Month is not valid");
        return;
      }

      let fileDate = moment({ year: Number(year), month: Number(month) - 1 })
      if (fileDate > maxRange || fileDate < minRange) {
        alert("Date not in range");
        return;
      }

      props.onUpload(fileToUpload, thumbFile, caption, notes, tags, fileDate);
    } catch (e) {
      alert(e)
      return;
    }
  }

  return (
    <Container>
      <Modal
        show={true}
        onHide={props.onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>{strings.uploadTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row noGutters>
              <Form className="mb-3" onSubmit={(event) => { event.preventDefault(); onUpload() }}>

                <InputGroup>

                  <Form.Control
                    type="file"
                    placeholder="=> choose file..."
                    onChange={handleFileChange}
                    dir={props.textDir}
                  />
                  {thumbUri && <InputGroup>
                    <img src={thumbUri} height="200px" width="200px" style={{ "marginLeft": "auto", "marginRight": "auto" }} />
                  </InputGroup>
                  }

                  <Form.Control
                    type="text"
                    placeholder={strings.caption}
                    onChange={(e) => setCaption(e.target.value)}
                    value={caption}
                    dir={props.textDir}
                  />
                </InputGroup>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder={strings.notes}
                    onChange={(e) => setNotes(e.target.value)}
                    value={notes}
                    dir={props.textDir}
                  />
                </InputGroup>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder={strings.tagPic}
                    onChange={(e) => setTagKeywords(e.target.value)}
                    value={tagKeywords}
                    dir={props.textDir}
                  />
                </InputGroup>
                <InputGroup>
                  <Select components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    defaultValue={month}
                    onChange={(e) => setMonth(e ? e.value : 12)}
                    options={months}
                    placeholder={strings.month}
                    className="select"
                    dir={props.textDir}
                  />
                  <Form.Control
                    type="number"
                    placeholder={strings.justYear}
                    onChange={(e) => setYear(e.target.value)}
                    value={year}
                    dir={props.textDir}
                  />
                </InputGroup>
              </Form>
            </Row>

          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" onClick={onUpload}>{strings.upload}</Button>
          <Button variant="secondary" onClick={props.onHide}>{strings.close}</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default FileUpload;
